import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Breadcrumb from '../components/breadcrumb'
import GenericPage from '../components/genericPage'

import content from '../json/datas.json'

const DatasPage = ({location}) => {
  return (
    <Layout simpleHeader={true} url="/vos-donnees">
      <SEO 
        title="Vos données"
        description="Gestion des données personnelles"
        path="/vos-donnees" />

      <Breadcrumb url="/vos-donnees" label="Vos données" year="2022" type="datas" /> 

      <GenericPage slug="credits" content={content} />
    </Layout>
  )
  }

export default DatasPage

